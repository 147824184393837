@import (reference) '../../assets/theme.less';

.grid-locations .ag-theme-material .ag-ltr {
  .ag-header-viewport {
    background: @primary-color;
  }

  .ag-header-cell,
  .ag-header-group-cell {
    color: #fff;
    border-top-color: @primary-color;
    border-bottom-color: @primary-color;
    border-right-color: @primary-color;
    background-color: @primary-color !important;

    &:first-of-type:not(.ag-header-cell-moving):hover,
    &:not(.ag-column-resizing) + .ag-header-cell:not(.ag-header-cell-moving):hover {
      background-color: @primary-active-color !important;
    }
  }

  .grid-locations .ag-theme-material .ag-ltr .ag-header-cell, .grid-locations .ag-theme-material .ag-ltr .ag-header-group-cell {
    background-color: @primary-color !important;
  }

  .ag-header-row .ag-header-cell:first-child {
    border-left: none;
  }

  .ag-icon {
    color: #fff !important;
  }

  .ag-row .ag-cell:first-child {
    border-left: solid 1px #f4f4f4;
  }

  .ag-theme-material .ag-ltr .ag-filter-apply-panel .ag-filter-apply-panel-button:last-child {
    background: @primary-color;
    border-color: @primary-color;
  }
  .ag-theme-material .ag-ltr .ag-checkbox-input-wrapper.ag-checked::after {
    color: @primary-color;
  }
}
