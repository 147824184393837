@import (reference) '../../assets/theme.less';

table.table-rate-card {
  th,
  td.text-center {
    text-align: center;
  }

  th,
  td {
    padding: 4px 8px;
  }

  td {
    border: solid 1px #e3e3e3;
  }

  thead {
    background-color: @primary-color;
    color: '#ffffff';
    padding: 5px;
    tr {
      th {
        padding: 8px;
        color: #ffffff;
      }
    }
  }
}

table.table-category-group {
  tr td:first-child {
    color: orange;
    text-decoration: underline;
    font-weight: 600;
  }
}

table.table-rate-card-note {
  tr:first-child td:first-child {
    color: @primary-active-color;
    text-decoration: underline;
    font-style: italic;
  }
}
