@import (reference) '../../assets/theme.less';

// @primary-color: #5e2e86;
// @primary-active-color: #5e2e86;

.ant-layout-sider {
  background-color: @primary-color !important;
}
.ant-menu-inline {
  background-color: @primary-color !important;

  .ant-menu-submenu-inline {
    > .ant-menu-submenu-title {
      .ant-menu-submenu-arrow {
        &::before,
        &::after {
          background: #fff;
        }
      }
    }
  }

  .ant-menu-item,
  .ant-menu-submenu-title {
    max-width: 100%;
  }

  .ant-menu-item > a,
  .ant-menu-submenu-title {
    color: #fff;
  }

  .ant-menu-item-active,
  .ant-menu-submenu-active {
    background-color: @primary-active-color;

    > a,
    a:hover,
    .ant-menu-submenu-title {
      color: #fff;
    }

    .ant-menu-submenu-arrow {
      &::before,
      &::after {
        background: @primary-color;
      }
    }
  }

  .ant-menu-item-selected {
    &::after {
      display: none;
    }
    > a,
    a:hover {
      color: @primary-active-color;
    }
  }
}
